const EventTypes = {
  OPEN_WAIT_LIST_DIALOG: "OPEN_WAIT_LIST_DIALOG",
  SIGNUP_STEP_CHANGED: "SIGNUP_STEP_CHANGED",
  TERMS_AND_CONDITIONS_CHANGED: "TERMS_AND_CONDITIONS_CHANGED",
  OPEN_COMMUNITY_DIALOG: "OPEN_COMMUNITY_DIALOG",
  OPEN_SIGNUP_FORM: "OPEN_SIGNUP_FORM",
  ON_PROMOTIONAL_STEP_CHANGE: "ON_PROMOTIONAL_STEP_CHANGE",
  ON_YEARLY_SUBSCRIPTION: "ON_YEARLY_SUBSCRIPTION",
  SELECTED_MONTH_CHANGED: "SELECTED_MONTH_CHANGED",
  SELECTED_SUBSCRIPTION_TYPE_CHANGED: "SELECTED_SUBSCRIPTION_TYPE_CHANGED",
};

export default EventTypes;
