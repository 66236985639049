import { Hidden } from "@material-ui/core";
import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";
import CommunityDialog from "../../components/community-dialog/CommunityDialog";
import Footer from "../../components/footer";
import MobileNavbar from "../../components/mobile-navbar";
import Navbar from "../../components/navbar";
import useStyles from "./styles";

interface DefaultLayoutProps {
  children: ReactNode;
}

function DefaultLayout({ children }: DefaultLayoutProps) {
  const classes = useStyles();

  return (
    <div className={classes.defaultLayout}>
      <Helmet>
        <noscript>
          {`
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-M3LTNHN"
            height="0"
            width="0"
            style="display:none;visibility:hidden"
          ></iframe>
          `}
        </noscript>
      </Helmet>
      <div className={classes.contentArea}>{children}</div>
      <CommunityDialog />
    </div>
  );
}

export default DefaultLayout;
