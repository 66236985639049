import { makeStyles } from "@material-ui/core";
import communityBackground from "../../assets/images/community-background.png";
import communityBackgroundMobile from "../../assets/images/community-background-mobile.png";

const useStyles = makeStyles((theme) => ({
  communityDialogContent: {
    minHeight: 675,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    backgroundImage: `url(${communityBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    [theme.breakpoints.down("xs")]: {
      minHeight: "100%",
      alignItems: "center",
      backgroundImage: `url(${communityBackgroundMobile})`,
      overflow: "hidden",
    },
  },
  headerArea: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  closeButton: {
    backgroundColor: "#FFF",
    color: "#7C8999",
    width: 40,
    height: 40,
    "&:hover": {
      backgroundColor: "#FFF",
    },
  },
  mainArea: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: 36,
  },
  logoArea: {
    flex: 1,
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  submitButtonArea: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  submitButton: {
    backgroundColor: "#3B9AD5",
    color: theme.palette.text.primary,
    width: 259,
    height: 50,
    fontSize: 24,
    "&:hover": {
      backgroundColor: "#3B9AD5 !important",
    },
  },
  submitButtonIcon: {
    fontSize: 24,
  },
}));

export default useStyles;
