import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  ArrowRightAlt as ArrowIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import communityLogoMobile from "../../assets/images/community-logo-mobile.png";
import communityLogo from "../../assets/images/community-logo.png";
import EventTypes from "../../constants/event-types";
import { navigateInSameTab } from "../../helpers/navigation-helper/navigation-helper";
import eventService from "../../services/event-service";
import useStyles from "./styles";

function CommunityDialog() {
  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [open, setOpen] = useState(false as boolean);

  useEffect(() => {
    eventService.addListener(EventTypes.OPEN_COMMUNITY_DIALOG, () => {
      setOpen(true);
    });

    return () =>
      eventService.removeListener(EventTypes.OPEN_COMMUNITY_DIALOG, () => {
        onClose();
      });
  }, []);

  function onClose(): void {
    setOpen(false);
  }
  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      fullScreen={isMobile}
      open={open}
      onClose={() => onClose()}
      PaperProps={{
        style: {
          borderRadius: isMobile ? 28 : 46,
        },
      }}
    >
      <DialogContent className={classes.communityDialogContent}>
        <div className={classes.headerArea}>
          <div>
            <IconButton
              className={classes.closeButton}
              onClick={() => onClose()}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className={classes.mainArea}>
          <div className={classes.logoArea}>
            <img
              src={isMobile ? communityLogoMobile : communityLogo}
              alt="Comunidade Smart Baby"
            />
          </div>
          <div className={classes.submitButtonArea}>
            <div>
              <Button
                variant="contained"
                className={classes.submitButton}
                endIcon={<ArrowIcon className={classes.submitButtonIcon} />}
                onClick={() =>
                  navigateInSameTab(
                    "https://pay.hotmart.com/H72276380A?checkoutMode=10&bid=1657583149240"
                  )
                }
              >
                Assine Já
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default CommunityDialog;
